import * as React from 'react';

import Layout from '../../components/layout';

export default () => (
  <Layout>
    <div className="bg-gray-100">
      <div className="pt-12 sm:pt-16 lg:pt-20">
        <div className="max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
          <div className="relative py-16 overflow-hidden bg-white">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
              <div className="relative h-full mx-auto text-lg max-w-prose">
                <svg
                  className="absolute transform translate-x-32 top-12 left-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
                  />
                </svg>
                <svg
                  className="absolute transform -translate-x-32 -translate-y-1/2 top-1/2 right-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
                  />
                </svg>
                <svg
                  className="absolute transform translate-x-32 bottom-12 left-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="d3eb07ae-5182-43e6-857d-35c643af9034"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
                  />
                </svg>
              </div>
            </div>
            <div className="relative px-4 sm:px-6 lg:px-8">
              <div className="mx-auto mb-6 text-lg max-w-prose">
                <p className="text-base font-semibold leading-6 tracking-wide text-center text-teal-600 uppercase">
                  Our process
                </p>
                <h1 className="mt-2 mb-8 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl sm:leading-10">
                  What we charge
                </h1>
                <p className="pb-8 text-xl leading-8 text-gray-500">
                  For most projects our hourly rate is $120. A basic
                  installation needs around 160 hours work, costing about
                  $20,000.
                </p>
                <h1 className="mt-2 mb-8 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl sm:leading-10">
                  What we&apos;ll do for you
                </h1>
                <p className="text-xl leading-8 text-gray-500">
                  Even though Odoo costs far less to purchase and operate than
                  direct competitors like Netsuite and SAP Business One, moving
                  from your one software system to another is not a small
                  undertaking.
                </p>
                <p className="py-8 text-xl leading-8 text-gray-500">
                  To make the decision an easy one for you, we work hard at
                  every point to be clear and exact about what we plan to do,
                  how long it&apos;ll take, and how much we&apos;ll charge.
                </p>
                <hr />
                <hr />
                <p className="py-8 text-xl leading-8 text-gray-500">
                  These are the steps we&apos;ll take to get you up and running
                  with Odoo.
                </p>
              </div>
              <div className="mx-auto prose prose-lg text-gray-500">
                <h2>
                  <svg
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 286.054 286.054"
                    style={{
                      fill: '#047481',
                      width: '30px',
                      height: '30px',
                      display: 'inline',
                      position: 'relative',
                      top: '-3px',
                      left: '-10px',
                    }}
                  >
                    <g>
                      <path
                        d="M143.027,0C64.031,0,0,64.04,0,143.027c0,78.996,64.031,143.027,143.027,143.027
s143.027-64.031,143.027-143.027C286.054,64.04,222.022,0,143.027,0z M143.027,259.236c-64.183,0-116.209-52.026-116.209-116.209
S78.844,26.818,143.027,26.818s116.209,52.026,116.209,116.209S207.21,259.236,143.027,259.236z"
                      />
                      <path
                        d="M150.026,80.39h-22.84c-6.91,0-10.933,7.044-10.933,13.158c0,5.936,3.209,13.158,10.933,13.158
h7.259v85.36c0,8.734,6.257,13.605,13.176,13.605s13.185-4.881,13.185-13.605V92.771C160.798,85.789,156.945,80.39,150.026,80.39z"
                      />
                    </g>
                  </svg>
                  No-obligation analysis
                </h2>
                <p>
                  <ul>
                    <li>
                      We&apos;ll talk with your key users, the people from each
                      department who need to decide whether Odoo is suitable for
                      their area of responsibility.
                    </li>
                    <li>
                      We&apos;ll work with them to figure out how Odoo can be
                      used to get their jobs done.
                    </li>
                    <li>
                      We&apos;ll build a custom Odoo demo using sample data from
                      your business so your key users can do a hands-on
                      evaluation.
                    </li>
                  </ul>
                </p>
                <h2>
                  <svg
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 286.054 286.054"
                    style={{
                      fill: '#047481',
                      width: '30px',
                      height: '30px',
                      display: 'inline',
                      position: 'relative',
                      top: '-3px',
                      left: '-10px',
                    }}
                  >
                    <g>
                      <path
                        d="M143.027,0C64.031,0,0,64.04,0,143.027c0,78.996,64.031,143.027,143.027,143.027
s143.027-64.031,143.027-143.027C286.054,64.04,222.022,0,143.027,0z M143.027,259.236c-64.183,0-116.209-52.026-116.209-116.209
S78.844,26.818,143.027,26.818s116.209,52.026,116.209,116.209S207.21,259.236,143.027,259.236z M173.232,180.205h-32.038
c15.661-18.459,40.852-39.753,40.852-63.736c0-21.91-16.564-35.882-39.216-35.882c-22.661,0-43.847,17.977-43.847,39.717
c0,6.731,4.604,12.586,13.445,12.586c17.691,0,8.108-28.498,29.294-28.498c7.554,0,13.266,6.204,13.266,13.284
c0,6.204-3.138,11.558-6.454,16.046c-13.999,18.969-30.581,34.496-45.867,51.579c-1.841,2.065-4.246,5.176-4.246,8.796
c0,7.938,6.266,11.38,14.365,11.38h61.528c6.999,0,13.266-4.568,13.266-12.497C187.58,185.05,181.331,180.205,173.232,180.205z"
                      />
                    </g>
                  </svg>
                  Detailed requirements
                </h2>
                <p>
                  We&apos;ll work with each of your key users to record in fine
                  detail:
                  <ul>
                    <li>
                      where your current process matches the standard Odoo
                      approach
                    </li>
                    <li>
                      where your process differs, but in ways that Odoo can be
                      adjusted to match
                    </li>
                    <li>
                      where your process differs in ways where you agree the
                      Odoo approach is worth switching to
                    </li>
                    <li>
                      where you prefer your approach strongly enough that you
                      want us to customize Odoo to match
                    </li>
                  </ul>
                </p>
                <p>
                  The result is a detailed requirements document which spells
                  out precisely what tasks must be done to adapt Odoo to your
                  operation.
                </p>
                <h2>
                  <svg
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 286.054 286.054"
                    style={{
                      fill: '#047481',
                      width: '30px',
                      height: '30px',
                      display: 'inline',
                      position: 'relative',
                      top: '-3px',
                      left: '-10px',
                    }}
                  >
                    <g>
                      <path
                        d="M143.027,0C64.04,0,0,64.04,0,143.027c0,78.996,64.04,143.027,143.027,143.027
s143.027-64.031,143.027-143.027C286.054,64.04,222.014,0,143.027,0z M143.027,259.236c-64.183,0-116.209-52.026-116.209-116.209
S78.844,26.818,143.027,26.818s116.209,52.026,116.209,116.209S207.21,259.236,143.027,259.236z M167.717,137.637
c8.966-5.936,13.364-15.277,13.364-25.977c0-13.239-11.254-31.082-34.729-31.082c-18.093,0-35.542,14.276-35.542,27.515
c0,6.284,3.915,12.56,10.602,12.56c11.085,0,8.966-16.636,24.449-16.636c7.339,0,11.737,4.925,11.737,11.371
c0,18.853-23.152,6.794-23.152,24.627c0,20.033,27.72,2.548,27.72,26.317c0,9.002-6.856,15.796-15.331,15.796
c-18.424,0-15.813-19.872-26.898-19.872c-5.873,0-12.551,4.756-12.551,11.38c0,13.418,15,31.922,39.127,31.922
c23.152,0,41.084-17.154,41.084-37.527C187.598,154.621,179.445,143.25,167.717,137.637z"
                      />
                    </g>
                  </svg>
                  Project planning
                </h2>
                <p>
                  We&apos;ll take the requirements document and plan out the
                  work, determining how long each task will take and when it
                  will be done. You&apos;ll use our plan to decide if you want
                  us to complete the project.
                </p>
                <h2>
                  <svg
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 286.054 286.054"
                    style={{
                      fill: '#047481',
                      width: '30px',
                      height: '30px',
                      display: 'inline',
                      position: 'relative',
                      top: '-3px',
                      left: '-10px',
                    }}
                  >
                    <g>
                      <path
                        d="M143.027,0C64.04,0,0,64.04,0,143.027c0,78.996,64.04,143.027,143.027,143.027
s143.027-64.031,143.027-143.027C286.054,64.04,222.014,0,143.027,0z M143.027,259.236c-64.183,0-116.209-52.026-116.209-116.209
S78.844,26.818,143.027,26.818s116.209,52.026,116.209,116.209S207.21,259.236,143.027,259.236z M175.065,155.122h-5.042v-52.607
c0-15.59-8.394-21.937-18.933-21.937c-9.449,0-14.535,3.093-18.531,9.94l-40.7,69.565c-1.091,1.707-2.548,3.772-2.548,7.545
c0,4.452,3.817,10.11,12.72,10.11h43.793V192.3c0,9.091,1.85,13.364,11.12,13.364s13.078-4.282,13.078-13.364v-14.562h5.042
c7.089,0,12.72-4.452,12.72-11.317C187.785,159.573,182.154,155.122,175.065,155.122z M146.379,155.122h-24.896l24.529-47.816
h0.367V155.122z"
                      />
                    </g>
                  </svg>
                  Odoo implementation
                </h2>
                <p>
                  With your approval, we&apos;ll proceed with the work &mdash;
                  configuring Odoo, creating customizations, and integrating as
                  needed with your other software packagea and the outside
                  world. Each piece will be tested and approved by your key
                  users.
                </p>
                <h2>
                  <svg
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 286.054 286.054"
                    style={{
                      fill: '#047481',
                      width: '30px',
                      height: '30px',
                      display: 'inline',
                      position: 'relative',
                      top: '-3px',
                      left: '-10px',
                    }}
                  >
                    <g>
                      <path
                        d="M143.027,0C64.04,0,0,64.04,0,143.027c0,78.996,64.04,143.027,143.027,143.027
s143.027-64.031,143.027-143.027C286.054,64.04,222.014,0,143.027,0z M143.027,259.236c-64.183,0-116.209-52.026-116.209-116.209
S78.844,26.818,143.027,26.818s116.209,52.026,116.209,116.209S207.21,259.236,143.027,259.236z M149.678,120.849
c-4.613,0-9.395,0.867-13.811,1.716l2.762-18.325h34.63c3.316,0,12.89-1.037,12.89-12.971c0-6.222-4.979-10.888-13.445-10.888
h-44.401c-8.832,0-12.712,2.941-14.365,16.43l-4.604,36.481c-0.188,1.895-0.554,3.629-0.554,5.873
c0,2.941,3.683,8.126,10.861,8.126c9.395,0,11.049-5.703,21.74-5.703c11.424,0,17.691,7.08,17.691,17.458
c0,10.897-6.633,22.643-19.523,22.643c-12.345,0-21.195-11.058-28.561-11.058c-6.812,0-12.515,5.364-12.515,11.755
c0,15.214,27.443,23.17,40.521,23.17c31.859,0,48.817-19.023,48.817-47.896C187.812,137.44,171.972,120.849,149.678,120.849z"
                      />
                    </g>
                  </svg>
                  Move your data
                </h2>
                <p>
                  Once we&apos;ve agreed that our Odoo implementation meets your
                  requirements, we&apos;ll export the data from your current
                  system, cleaning and massaging it as needed, and load it into
                  Odoo for testing.
                </p>
                <h2>
                  <svg
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 286.054 286.054"
                    style={{
                      fill: '#047481',
                      width: '30px',
                      height: '30px',
                      display: 'inline',
                      position: 'relative',
                      top: '-3px',
                      left: '-10px',
                    }}
                  >
                    <g>
                      <path
                        d="M143.027,0C64.04,0,0,64.04,0,143.027c0,78.996,64.04,143.027,143.027,143.027
s143.027-64.031,143.027-143.027C286.054,64.04,222.014,0,143.027,0z M143.027,259.236c-64.183,0-116.209-52.026-116.209-116.209
S78.844,26.818,143.027,26.818s116.209,52.026,116.209,116.209S207.21,259.236,143.027,259.236z M150.5,125.417
c-3.915,0-8.01,0.679-11.228,2.047l-0.349-0.349c5.873-7.983,17.458-18.844,24.583-27.166c2.136-2.548,3.737-4.586,3.737-6.955
c0-6.123-7.652-12.408-12.64-12.408c-1.43,0-3.388,0-5.346,1.198c-2.145,1.35-4.282,2.718-5.712,4.076
c-17.995,15.956-45.25,51.955-45.25,77.61c0,22.58,17.288,42.112,44.723,42.112c26.371,0,44.723-20.042,44.723-42.112
C187.741,143.411,171.892,125.417,150.5,125.417z M143.018,181.117c-10.691,0-16.931-8.153-16.931-17.664
c0-10.021,6.776-17.673,16.931-17.673c11.228,0,16.931,8.671,16.931,17.673C159.949,173.304,153.173,181.117,143.018,181.117z"
                      />
                    </g>
                  </svg>
                  Test
                </h2>
                <p>
                  With your data loaded into Odoo, your key users will test each
                  required function against your current software to insure that
                  Odoo performs correctly. In each case we&apos;ll adjust as
                  needed until you are ready to sign off.
                </p>
                <h2>
                  <svg
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 286.054 286.054"
                    style={{
                      fill: '#047481',
                      width: '30px',
                      height: '30px',
                      display: 'inline',
                      position: 'relative',
                      top: '-3px',
                      left: '-10px',
                    }}
                  >
                    <g>
                      <path
                        d="M143.027,0C64.04,0,0,64.04,0,143.027c0,78.996,64.04,143.027,143.027,143.027
s143.027-64.031,143.027-143.027C286.054,64.04,222.014,0,143.027,0z M143.027,259.236c-64.183,0-116.209-52.026-116.209-116.209
S78.844,26.818,143.027,26.818s116.209,52.026,116.209,116.209S207.21,259.236,143.027,259.236z M175.047,80.39h-63.674
c-9.672,0-13.007,5.927-13.007,12.041c0,5.936,3.522,12.041,13.007,12.041H156.4l-43.963,82.714
c-1.243,2.431-1.761,4.005-1.761,6.105c0,5.06,4.925,12.39,14.598,12.39c7.92,0,9.851-3.486,14.768-13.257l43.614-87.944
c2.11-4.362,4.049-8.018,4.049-11.514C187.705,88.409,184.013,80.39,175.047,80.39z"
                      />
                    </g>
                  </svg>
                  Training
                </h2>
                <p>
                  By now your key users are already trained in using Odoo to run
                  your business. They&apos;ll now train the rest of your users,
                  will full support from us.
                </p>
                <h2>
                  <svg
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 286.054 286.054"
                    style={{
                      fill: '#047481',
                      width: '30px',
                      height: '30px',
                      display: 'inline',
                      position: 'relative',
                      top: '-3px',
                      left: '-10px',
                    }}
                  >
                    <g>
                      <path
                        d="M143.027,0C64.04,0,0,64.04,0,143.027c0,78.996,64.04,143.027,143.027,143.027
c78.996,0,143.027-64.031,143.027-143.027C286.054,64.04,222.022,0,143.027,0z M143.027,259.236
c-64.183,0-116.209-52.026-116.209-116.209S78.844,26.818,143.027,26.818s116.209,52.026,116.209,116.209
S207.21,259.236,143.027,259.236z M168.289,137.467c8.85-5.265,13.909-14.768,13.909-24.279c0-20.042-18.236-32.61-39.18-32.61
c-21.481,0-38.993,12.747-38.993,32.61c0,10.691,5.238,18.504,13.722,24.279c-11.371,5.775-19.318,15.796-19.318,29.383
c0,22.759,19.675,38.716,44.589,38.716c24.189,0,44.589-15.107,44.589-38.716C187.607,153.781,180.571,143.25,168.289,137.467z
 M143.018,102.997c8.483,0,13.185,5.605,13.185,12.908c0,7.634-4.881,12.899-13.185,12.899c-8.305,0-12.998-5.265-12.998-12.899
C130.02,108.602,134.535,102.997,143.018,102.997z M143.018,182.127c-11.013,0-16.43-8.322-16.43-16.976
c0-9.502,5.953-16.976,16.43-16.976c9.922,0,16.43,6.284,16.43,16.976C159.448,173.813,153.307,182.127,143.018,182.127z"
                      />
                    </g>
                  </svg>
                  Go live
                </h2>
                <p>
                  You&apos;ll begin running your Odoo installation in parallel
                  with your current software until you&apos;re satisfied that
                  we&apos;ve met all requirements and Odoo is working properly.
                  At this point you&apos;ll shut down your current software.
                </p>
                <h2>
                  <svg
                    version="1.1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 286.054 286.054"
                    style={{
                      fill: '#047481',
                      width: '30px',
                      height: '30px',
                      display: 'inline',
                      position: 'relative',
                      top: '-3px',
                      left: '-10px',
                    }}
                  >
                    <g>
                      <path
                        d="M143.027,0C64.031,0,0,64.04,0,143.027c0,78.996,64.031,143.027,143.027,143.027
s143.027-64.031,143.027-143.027C286.054,64.04,222.022,0,143.027,0z M143.027,259.236c-64.183,0-116.209-52.026-116.209-116.209
S78.844,26.818,143.027,26.818s116.209,52.026,116.209,116.209S207.21,259.236,143.027,259.236z M143.018,80.578
c-26.371,0-44.723,20.042-44.723,42.121c0,20.033,15.858,38.036,37.241,38.036c3.915,0,8.01-0.679,11.228-2.038l0.349,0.34
c-5.873,7.983-17.458,18.844-24.583,27.166c-2.136,2.548-3.746,4.595-3.746,6.964c0,6.114,7.661,12.399,12.649,12.399
c1.43,0,3.388,0,5.355-1.189c2.136-1.359,4.273-2.726,5.703-4.076c17.995-15.965,45.25-51.963,45.25-77.61
C187.741,100.119,170.452,80.578,143.018,80.578z M143.018,140.363c-11.228,0-16.931-8.662-16.931-17.664
c0-9.86,6.776-17.664,16.931-17.664c10.691,0,16.931,8.153,16.931,17.664C159.949,132.711,153.173,140.363,143.018,140.363z"
                      />
                    </g>
                  </svg>
                  Ongoing support
                </h2>
                <p>
                  Once you&apos;ve gone live, we&apos;ll provide ongoing support
                  for free.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);
